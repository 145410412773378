import React, { useState } from "react";
import img3 from "../Media/img-projects-2.jpg";
import img4 from "../Media/img-projects-3.png";
import img6 from "../Media/img-projects-5.jpeg";
import img8 from "../Media/dynamics.png";
import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

window.scrollTo(0, 0);
export default function Projects() {
  const [expandedState, setExpandedState] = useState({});

  const toggleExpand = (id, event) => {
    event.stopPropagation();
    setExpandedState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const getClassNames = (id) => {
    return `image-text-box-r ${expandedState[id] ? "expanded" : ""}`;
  };

  const getText = (id) => {
    return expandedState[id] ? "Zusammenklappen" : "Mehr lesen ⇣";
  };

  return (
    <div className="projects">
      <div className="page-content">



        <div className={getClassNames("block1")}>
          <ProgressiveImageCustom src={img8} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>Microsoft Dynamics: Entwicklung oder Anpassung von Microsoft Dynamics Unternehmenslösungen</b>
            </p>
            <p className="paragraph-disappear">
              Wir optimieren Ihre Geschäftsprozesse mit maßgeschneiderten Lösungen in Microsoft Dynamics 365. Unsere Leistungen umfassen eine Vielzahl von Ansätzen, um Ihre Kernabläufe effizienter zu gestalten und perfekt in Dynamics 365 abzubilden.
              <br />
              <br />
              <strong>Unsere Leistungen im Überblick:</strong>
              <ul style={{ marginTop: "1rem", lineHeight: "1.8rem" }}>
                <li>
                  <strong>C# Plugins & Scripting:</strong> Entwicklung leistungsstarker Plugins und Skripte, um Dynamics 365 um spezifische Funktionen zu erweitern.
                </li>
                <li>
                  <strong>Power Automate Flows & Workflows:</strong> Automatisieren Sie Ihre Abläufe mit benutzerdefinierten Flows und Workflows für maximale Effizienz.
                </li>
                <li>
                  <strong>Prozessanalyse & Abbildung:</strong> Detaillierte Analyse Ihrer Geschäftsprozesse, Optimierung und passgenaue Umsetzung in Dynamics 365.
                </li>
                <li>
                  <strong>CRM-Anpassungen & Konfiguration:</strong> Individuelle Anpassung von Entitäten, Formularen, Ansichten und Dashboards, um Ihre Anforderungen optimal zu erfüllen.
                </li>
                <li>
                  <strong>Systemintegration:</strong> Nahtlose Verknüpfung externer Systeme und interner Lösungen für eine zentrale Datenbasis.
                </li>
              </ul>

              <br />
              Microsoft Dynamics 365 bietet mit seiner adaptiven Architektur eine Plattform, die sich dynamisch an die evolutionären Anforderungen Ihres Geschäfts anpasst. Erstklassige Sicherheits- und Compliance-Maßnahmen gewährleisten, dass Ihre Unternehmensdaten stets geschützt sind. Entdecken Sie, wie maßgeschneiderte Dynamics-Lösungen Ihre Effizienz steigern, Prozesse vereinfachen und smartere Geschäftsentscheidungen ermöglichen.
            </p>


            <button
              onClick={(event) => toggleExpand("block1", event)}
              className="expand-collapse-btn"
              aria-label={getText("block1")}
            >
              {getText("block1")}
            </button>
          </div>
        </div>

        <div className={getClassNames("block2")}>
          <ProgressiveImageCustom src={img6} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>Komplettlösungen für Digitale Präsenz: Design, Entwicklung und Langzeitbetreuung von Websites und Webapplikationen</b>
            </p>
            <p className="paragraph-disappear">
              Wir ge­stal­ten, ent­wickeln und be­treuen Ihre Web­site oder Web­App, in­dem wir mo­der­ne Tech­no­lo­gien wie das React-Fram­e­work so­wie klas­si­sche Web­ent­wick­lung mit HTML, CSS und JavaScript nutzen. Un­ser An­ge­bot be­schränkt sich nicht nur auf das De­sign des Front­ends. Wir küm­mern uns eben­falls um er­weiterte Funk­tionen, dar­un­ter Be­nut­zer­an­mel­dungen oder die Ein­bin­dung von Web­shops, kom­plett mit der not­wen­digen Lo­gik im Back­end. Un­ser An­satz zielt dar­auf ab, Ihre di­gi­ta­le Prä­senz mit einer Mischung aus tech­ni­schem Know­how und krea­tivem De­sign zu ver­stär­ken. Wir le­gen Wert dar­auf, Lö­sungen zu bie­ten, die so­wohl op­tisch an­spre­chend als auch funk­ti­o­nal sind, um die Be­dürf­ nis­se Ihrer Nut­zer op­ti­mal zu er­fül­len.
            </p>
            <button
              onClick={(event) => toggleExpand("block2", event)}
              className="expand-collapse-btn"
              aria-label={getText("block2")}
            >
              {getText("block2")}
            </button>
          </div>
        </div>

        <div className={getClassNames("block3")}>
          <ProgressiveImageCustom src={img3} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>Entwicklung von Google Chrome Extensions: Maßgeschneiderte Browser-Erweiterungen für optimierte Nutzung und Produktivität</b>
            </p>
            <p className="paragraph-disappear">
              Möchten Sie Ihre Ideen und Funktionalitäten direkt in den Chrome-Browser integrieren? Mit unserer Expertise in der Entwicklung von Google Chrome Erweiterungen wird dies Realität. Wir entwickeln Chrome Extensions, welche die neusten Sicherheitsanforderungen (Manifest V.3) des Google Chrome Browsers erfüllen, vom UX-Design bis zum fertigen, ausführbarem Plugin.
            </p>
            <button
              onClick={(event) => toggleExpand("block3", event)}
              className="expand-collapse-btn"
              aria-label={getText("block3")}
            >
              {getText("block3")}
            </button>
          </div>
        </div>

        <div className={getClassNames("block4")}>
          <ProgressiveImageCustom src={img4} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-tile-title">
              <b>Spreadsheet Automationen: Maßgeschneiderte Lösungen für Effizienzsteigerung in Tabellenkalkulationsprogrammen</b>
            </p>
            <p className="paragraph-disappear">
              Wir implementieren Ihre mass­ge­schneiderten und performanten Auto­matisierungs­scripts in den gängigsten Tabellen­kalkulations­programmen wie Google Spreadsheet und Microsoft Excel.
            </p>
            <button
              onClick={(event) => toggleExpand("block4", event)}
              className="expand-collapse-btn"
              aria-label={getText("block4")}
            >
              {getText("block4")}
            </button>
          </div>
        </div>

      </div>
    </div>


  );
}


