import React, { useEffect } from 'react';

const DynamicsFormComponent = () => {
  useEffect(() => {
    // Function to dynamically load a script
    const loadScript = (src, id) => {
      // Check if the script is already included
      if (!document.getElementById(id)) {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.id = id;
        document.body.appendChild(script);
      }
    };

    // Load the FormLoader script
    loadScript(
      'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormLoader/FormLoader.bundle.js',
      'dynamics-form-loader'
    );

    // Set the document language (equivalent to <script>document.documentElement.lang = navigator.language;</script>)
    document.documentElement.lang = navigator.language;
  }, []);

  return (
    <div
      data-form-id="c988ef08-f1b0-ef11-b8e8-000d3a672dc3"
      data-form-api-url="https://public-eur.mkt.dynamics.com/api/v1.0/orgs/7d53da9d-b3c1-ee11-9073-000d3a67cc76/eventmanagement"
      data-cached-form-url="https://assets-eur.mkt.dynamics.com/7d53da9d-b3c1-ee11-9073-000d3a67cc76/digitalassets/forms/c988ef08-f1b0-ef11-b8e8-000d3a672dc3"
      data-readable-event-id="test1197884302"
    ></div>
  );
};

export default DynamicsFormComponent;
