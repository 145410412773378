import React from "react";

import img1 from "../Media/img-service.jpg";
import img3 from "../Media/img-service-2.JPG";
import img4 from "../Media/img-service-3.JPG";



import ProgressiveImageCustom from "../Components/ProgressiveImageCustom.tsx";

export default function Service() {
  window.scrollTo(0, 0);
  return (
    <div className="contact">

      <div className="page-content">

        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img3}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-no-hide">
              Jedes Projekt ist einzigartig, und wir passen uns flexibel an Ihre
              individuellen Anforderungen an. Mit einem starken Fokus auf agile
              Methoden und transparente Kommunikation entwickeln wir gemeinsam eine
              maßgeschneiderte Lösung, die genau zu Ihren Zielen passt.
              Dabei denken wir nicht nur an Ihre aktuellen Bedürfnisse, sondern
              planen vorausschauend, um zukünftige Entwicklungen zu berücksichtigen
              und Ihre Investition nachhaltig abzusichern. Mit uns erhalten Sie
              Lösungen, die überzeugen und auch morgen Bestand haben.
            </p>
          </div>
        </div>


        <div className="image-text-box-r">
          <ProgressiveImageCustom src={img4}></ProgressiveImageCustom>
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              {" "}
              Wir kreieren mass&shy;geschneiderte Websites, die nicht nur
              beein&shy;drucken, sondern auch nahtlose Benutzer&shy;erfahrungen
              bieten. Von modernem Responsive Design bis zur perfekten
              Ver&shy;schmelzung Ihrer Marken&shy;ästhetik.
            </p>
            <p className="img-text-r-no-hide">
              {" "}
              Wir sind nicht nur während der Entwicklungs&shy;phase an Ihrer
              Seite, sondern auch danach. Unsere Wartungs- und
              Supportdienst&shy;leistungen sorgen dafür, dass Ihre Website immer
              auf dem neuesten Stand ist und reibungslos läuft.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
