import React, { useState, useEffect } from "react";
import img4 from "../Media/Pax8.svg";

import { BiLogoJava } from "react-icons/bi";
import { IoLogoPython } from "react-icons/io";
import { TbSql } from "react-icons/tb";
import { SiJavascript } from "react-icons/si";
import { BiLogoReact } from "react-icons/bi";
import { BsMicrosoft } from "react-icons/bs";
import { FaChrome } from "react-icons/fa";
import { BiLogoPostgresql } from "react-icons/bi";
import { AiFillHtml5 } from "react-icons/ai";
import { BiLogoCss3 } from "react-icons/bi";
import { TbBrandNpm } from "react-icons/tb";
import { SiMysql } from "react-icons/si";
import { SiTypescript } from "react-icons/si";
import { FaNodeJs } from "react-icons/fa";
import { DiDocker } from "react-icons/di";
import { SiKubernetes } from "react-icons/si";
import { GrGraphQl } from "react-icons/gr";
import { SiMongodb } from "react-icons/si";


import LottieAnimation from '../Components/LottieAnimation.tsx';


window.scrollTo(0, 0);

export default function Menu() {

  return (
    <div className="home">


      <div className="page-content">
        <p className="why-next-node">Willkommen bei NextNode</p>



        <div className="image-text-box-r">
          <LottieAnimation animationKey="animation3" height={240} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-no-hide">
              Die Digitalisierung ist mehr als nur ein Trend – sie ist der Wegbereiter für nachhaltigen
              Erfolg und langfristige Wettbewerbsfähigkeit.
              Wir unterstützen Sie dabei, diesen Weg strategisch und zukunftsorientiert zu gehen.
            </p>
          </div>
        </div>


        <div className="image-text-box-r">
          <LottieAnimation animationKey="animation5" height={240} />
          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-no-hide">
              Mit NextNode profitieren Sie von einem Partner der Ihre
              Prozesse versteht, optimiert und sie in eine geordnete Prozesslandschaft überführt.


            </p>
          </div>
        </div>


        <p className="why-next-node">Warum NextNode?</p>

        <div className="image-text-box-r">
          <LottieAnimation animationKey="animation1" height={240} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              In der heutigen Welt ist eine zuver&shy;lässige und effiziente
              digitale Infra&shy;struktur der entscheidende Baustein zum Erfolg.
              Bei NextNode verstehen wir, dass jede Organi&shy;sation in ihren
              Bedürfnissen und Anforder&shy;ungen einzigartig ist. Unser Ziel
              ist es so effizient wie möglich zu erruieren, welche Lösung Sie
              brauchen.
            </p>
          </div>
        </div>


        <div className="image-text-box-r">

          <div className="parapgraphs-stacked-r">
            <p className="img-text-r-no-hide">
              <div className="skillset">
                <BiLogoJava className="react-icons" size={45} color="#FFFFFF" />
                <IoLogoPython className="react-icons" size={45} color="#FFFFFF" />
                <TbSql className="react-icons" size={45} color="#FFFFFF" />
                <SiJavascript className="react-icons" size={45} color="#FFFFFF" />
                <BiLogoReact className="react-icons" size={45} color="#FFFFFF" />
                <BsMicrosoft className="react-icons" size={45} color="#FFFFFF" />
                <FaChrome className="react-icons" size={45} color="#FFFFFF" />
                <BiLogoPostgresql className="react-icons" size={45} color="#FFFFFF" />
                <AiFillHtml5 className="react-icons" size={45} color="#FFFFFF" />
                <BiLogoCss3 className="react-icons" size={45} color="#FFFFFF" />
                <TbBrandNpm className="react-icons" size={45} color="#FFFFFF" />
                <SiMysql className="react-icons" size={45} color="#FFFFFF" />
                <SiTypescript className="react-icons" size={45} color="#FFFFFF" />
                <div>
                  <FaNodeJs className="react-icons" size={45} color="#FFFFFF" />
                </div>
                <div>
                  <DiDocker className="react-icons" size={45} color="#FFFFFF" />
                </div>
                <div>
                  <SiKubernetes className="react-icons" size={45} color="#FFFFFF" />
                </div>
                <div>
                  <GrGraphQl className="react-icons" size={45} color="#FFFFFF" />
                </div>
                <div>
                  <SiMongodb className="react-icons" size={45} color="#FFFFFF" />
                </div>

              </div>
              Wir bedienen eine breite Palette von Programm&shy;iersprachen und
              Techno&shy;logien und ziehen wenn nötig die richtigen Experten
              dazu. Egal ob Sie eine Webanwendung, eine mobile App oder
              Unternehmens&shy;software benötigen – wir haben das Know-how, um
              Ihre Vision in die Realität umzu&shy;setzen.
            </p>
          </div>
        </div>

        <div className="image-text-box-r">
          <LottieAnimation animationKey="animation2" height={320} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              NextNode ist kein IT-Koloss. Wir kommen aus dem ❤️ von Basel und
              Projekt&shy;realisierung mit uns bedeutet kurze
              Antwort&shy;zeiten, Eingehen auf individu&shy;elle Bedürfnisse und
              Änder&shy;ungen nach dem Prinzip der agilen Arbeits&shy;weise,
              offiziell zertifiziert durch Scrum Inc.{" "}
            </p>
          </div>
        </div>

        <div className="image-text-box-r">
          <LottieAnimation animationKey="animation4" height={240} />
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              Unser Partner&shy;netzwerk trägt jahr&shy;zehnte&shy;lange
              Erfahrung in der IT-Branche zusammen und verhilft Ihrem Projekt
              effizient, elegant und schlank zum Erfolg.
            </p>
          </div>
        </div>


        <div className="image-text-box-r">
          <img className="pax-8-logo" src={img4}></img>
          <div className="parapgraphs-stacked-r">

            <p className="img-text-r-no-hide">
              Durch unsere Partnerschaft mit pax8, dem führenden Anbieter im Cloud-Lizenz&shy;management, bieten wir Ihnen eine maß&shy;geschneiderte Verwaltung Ihrer Lizenz&shy;landschaft an. Unser Ziel ist es, Ihnen nicht nur Zeit und Ressourcen zu sparen, sondern auch sicher&shy;zustellen, dass Sie immer die richtigen Lizenzen für Ihre Bedürfnisse haben.

            </p>
          </div>
        </div>

      </div>
    </div>
  );
}
