import React from 'react';
import Lottie from 'react-lottie';
import animation1 from '../Media/graph-chart-animation.json';
import animation2 from '../Media/sculpturing-animation.json';
import animation3 from '../Media/lottie-working-in-office-animation.json';
import animation4 from '../Media/lottie-network-animation.json';
import animation5 from '../Media/straight-lines.json';


const animations = {
    animation1,
    animation2,
    animation3,
    animation4,
    animation5
  };

  const LottieAnimation = ({ animationKey, height = 300, width = 300 }) => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      speed: 0.3,
      animationData: animations[animationKey], // Select animation based on key
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    const style = {
        marginLeft: '10px', // Adjust the margin size as needed
        marginRight: '10px', // Adjust the margin size as needed
        marginTop: '10px'

      };
    
      return <Lottie options={defaultOptions} height={height} width={width} style={style} />;
    };

  
  export default LottieAnimation;