import ProgressiveImage from "react-progressive-graceful-image";
import React from "react";

export default function ProgressiveImageCustom({ src }) {
  return (
    <div className="progressive-image-container">
      <ProgressiveImage delay={1000} src={src} placeholder={src}>
        {(src, loading) => (
          <img
            className="image-listing-1-r"
            src={src}
            alt=""
            style={{
              width: "100%", 
              height: "100%",
              objectFit: "cover", // Ensure cropping without distortion
              visibility: loading ? "hidden" : "visible", // Avoid flashing placeholder
            }}
          />
        )}
      </ProgressiveImage>
    </div>
  );
}
